<template>
	<div class="body-function">
		<div style="display: inline-block; width: 100%; height: 100%">
			<div class="child-top" style="padding-top: 0">
				<div class="input-from">
					<el-date-picker
						v-model="stageDate"
						type="daterange"
						align="right"
						unlink-panels
						format="yyyy-MM-dd"
						value-format="yyyy-MM-dd"
						range-separator="至"
						start-placeholder="开始日期"
						end-placeholder="结束日期"
						@change="create"
						class="picker_date"
					></el-date-picker>
					<el-cascader
						@change="getStaffList"
						style="float: left"
						placeholder="请选择运动队"
						clearable
						v-model="deparmentId"
						:options="departmentOptions"
						:show-all-levels="false"
						collapse-tags
						:props="{ children: 'children', label: 'name', value: 'uuid', emitPath: false, multiple: true }"
					></el-cascader>
					<el-select v-model="staffValue" multiple collapse-tags style="width: 200px;" placeholder="请选择运动员" @change="getStaffInfo">
						<el-option v-for="item in staffList" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
					</el-select>

					<el-cascader
						v-model="provideValue"
						:options="provideOptions"
						placeholder="请选择测试指标"
						:props="{ multiple: true, value: 'uuid', label: 'name', children: 'children' }"
						collapse-tags
						clearable
						@change="provideChange"
						style="width: 260px;"
					></el-cascader>

					<!-- <div class="text_demo" @click="selectTextIndex" placeholder="请选择测试指标">
						<p v-if="!demoSelectData.length">请选择测试指标</p>
						<span v-for="(item, index) in demoSelectData" :key="index">
							{{ item.name }}
							<i class="el-icon-close" @click.stop="demoCancel(item.uuid, index)"></i>
						</span>
					</div> -->
				</div>

				<div class="operation-button">
					<!-- <el-button @click="print" class="print_btn" round>
						<span>打印</span>
					</el-button> -->
					<!-- <el-button class="export_btn" round @click="expor">
						<span>导出</span>
					</el-button> -->

					<!-- <el-button @click="savePersonAnalysis" class="new_btn" style="background: #0055e9" operation-button round>
						<span>生成报告</span>
					</el-button> -->
				</div>
			</div>

			<div class="analysis" key="analysis" v-if="tableData.length">
				<el-table :data="tableData" ref="myTable" style="width: 100%" border :span-method="arraySpanMethod">
					<el-table-column prop="index" label="序号" width="50" align="center" fixed>
						<template slot-scope="scope">{{ scope.$index + 1 }}</template>
					</el-table-column>
					<el-table-column prop="name" label="运动员" width="80" align="center" fixed> </el-table-column>
					<el-table-column prop="sex" label="性别" width="50" align="center" fixed>
						<template slot-scope="scope">
							{{ scope.row.sex == 1 ? '男' : '女' }}
						</template>
					</el-table-column>
					<el-table-column prop="age" label="年龄" width="50" align="center" fixed> </el-table-column>
					<el-table-column prop="department_name" label="运动队" width="80" align="center" fixed> </el-table-column>
					<el-table-column prop="sport_name" label="项目" width="80" align="center" fixed> </el-table-column>
					<el-table-column prop="date" label="日期" width="100" align="center" fixed> </el-table-column>
					<el-table-column v-for="(item, index) in tableHeader" :key="index + 1" :label="item.name" align="center">
						<el-table-column v-for="(itm, idx) in item.children" :key="idx + '11'" :prop="itm.prop" :label="itm.name" align="center" width="100">
							<template slot-scope="scope">
								<div :class="'analysis_table_content' + (index + 1)">{{ scope.row[itm.prop] }}</div>
							</template>
						</el-table-column>
					</el-table-column>
				</el-table>

				<div class="echarts_content">
					<!-- <el-select v-model="value" placeholder="请选择">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select> -->
					<div class="echarts_content_fifter">
						<el-select v-model="echarts_staff_uuid" style="width: 35%" filterable placeholder="请选择" @change="getAnalysisEcharts">
							<el-option v-for="item in echarts_teammenData" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
						</el-select>

						<el-select v-model="echarts_cate_value" style="width: 60%;margin-left: 5%;" filterable multiple collapse-tags placeholder="请选择" @change="getAnalysisEcharts">
							<el-option v-for="item in echarts_IndicatorOptions" :key="item.uuid" :label="item.name" :value="item.uuid"> </el-option>
						</el-select>

						<!-- <el-cascader
							:options="echarts_IndicatorOptions"
							collapse-tags
							clearable
							style="width: 55%;"
							@change="getAnalysisEcharts"
							v-model="echarts_cate_value"
							:props="{
								label: 'name',
								value: 'uuid',
								children: 'chlid',
								multiple: true,
								emitPath: false,
							}"
						></el-cascader> -->
					</div>
					<div class="analysis_echarts" id="analysisEcharts"></div>
				</div>
			</div>

			<div class="no_data_view" v-if="!tableData.length">
				<img src="./../../../static/no_data.png" alt="" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			stageDate: [],
			deparmentId: [],
			staffValue: [],
			staffList: [],
			departmentOptions: [],
			provideOptions: [],
			provideValue: [],

			tableData: [],
			tableHeader: [],

			echarts_staff_uuid: '',
			echarts_teammenData: [],

			echarts_IndicatorOptions: [],
			echarts_cate_value: [],

			echartsData: [],

			echartsStaffList: [],
			echartsStaffValue: '',

			echarts_title: '',
		}
	},
	mounted() {
		this.$axios.post('/p/cate/jointNorm').then(res => {
			this.provideOptions = res.data.data.rows
		})

		this.getDeparment()
	},
	methods: {
		// 获取运动队
		getDeparment() {
			this.$axios.post('/p/staff/tree').then(res => {
				if (res.data.code == 0) {
					this.departmentOptions = res.data.data.department
				} else {
					this.$message({
						type: 'error',
						message: res.data.message,
					})
				}
			})
		},
		create() {
			this.getPhymentChange()
		},
		getStaffList() {
			this.staffValue = []
			if (!this.deparmentId.length) {
				this.$set(this, 'staffList', [])
				return false
			}
			return new Promise(() => {
				this.$axios
					.post('/p/staff/lists', {
						department_uuid_str: this.deparmentId.toString(),
					})
					.then(res => {
						if (res.data.code == 0) {
							this.staffList = res.data.data.rows
						} else {
							this.$message({
								type: 'error',
								message: res.data.message,
							})
						}
					})
			})
		},
		getStaffInfo() {
			this.echarts_teammenData = []
			this.staffValue.forEach(itm => {
				this.staffList.forEach(im => {
					if (itm == im.uuid) {
						this.echarts_teammenData.push(im)
					}
				})
			})
			this.getPhymentChange()

			if (!this.echarts_staff_uuid) {
				this.echarts_staff_uuid = this.echarts_teammenData.length && this.echarts_teammenData[0].uuid
				this.getAnalysisEcharts()
			}

			this.echarts_staff_uuid = this.echarts_teammenData.length && this.echarts_teammenData[0].uuid
		},

		provideChange() {
			this.echarts_cate_value = []
			var c_index = [],
				c_data = []
			this.provideValue.forEach(item => {
				if (c_index.indexOf(item[0]) == -1) {
					c_index.push(item[0])
					this.provideOptions.forEach(im => {
						if (im.uuid == item[0]) {
							c_data.push(im)
						}
					})
				}
			})
			this.echarts_IndicatorOptions = c_data
			this.getPhymentChange()

			if (!this.echarts_cate_value.length) {
				this.$nextTick(() => {
					this.getPhymentEcharts()
				})
			}
			this.echarts_cate_value = [c_data.length && c_data[0].uuid]
		},

		getPhymentChange() {
			this.$axios
				.post(
					'/p/analyse/jointAnalyse',
					this.$qs({
						staff_uuid_str: this.staffValue.toString(''),
						start_date: this.stageDate ? this.stageDate[0] : '',
						end_date: this.stageDate ? this.stageDate[1] : '',
						cate_norm: JSON.stringify(this.provideValue),
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.tableHeader = res.data.data.header
						this.tableData = res.data.data.rows

						this.$nextTick(() => {
							// myTable是表格的ref属性值
							if (this.$refs.myTable && this.$refs.myTable.doLayout) {
								this.$refs.myTable.doLayout()
							}
						})
					} else {
						return this.$message({
							type: 'warning',
							message: res.data.message,
						})
					}
				})
		},

		getPhymentEcharts() {
			this.$axios
				.post(
					'/p/analyse/jointAnalyseCharts',
					this.$qs({
						staff_uuid_str: this.echarts_staff_uuid,
						start_date: this.stageDate ? this.stageDate[0] : '',
						end_date: this.stageDate ? this.stageDate[1] : '',
						cate_norm: JSON.stringify(this.provideValue),
						chart_cate: JSON.stringify(this.echarts_cate_value),
					})
				)
				.then(res => {
					if (res.data.code == 0) {
						this.echartsData = res.data.data
						this.$nextTick(() => {
							this.drawLine()
						})
					} else {
						return this.$message({
							type: 'warning',
							message: res.data.message,
						})
					}
				})
		},

		savePersonAnalysis() {},
		print() {},
		expor() {},

		arraySpanMethod({ row, column, rowIndex, columnIndex }) {
			if (row.name !== '平均值') {
				if ((columnIndex == 1 || columnIndex == 2 || columnIndex == 3 || columnIndex == 4 || columnIndex == 5) && row.index == 1) {
					return {
						rowspan: row.num_sum,
						colspan: 1,
					}
				} else if ((columnIndex == 1 || columnIndex == 2 || columnIndex == 3 || columnIndex == 4 || columnIndex == 5) && row.index !== 1) {
					return {
						rowspan: 0,
						colspan: 0,
					}
				}
			} else if (row.name == '平均值') {
				if (columnIndex == 0 || columnIndex == 2 || columnIndex == 3 || columnIndex == 4 || columnIndex == 5 || columnIndex == 6) {
					return {
						rowspan: 0,
						colspan: 0,
					}
				} else if (columnIndex == 1) {
					return [1, 7]
				}
			}
		},

		getAnalysisEcharts() {
			this.echarts_teammenData.forEach(itm => {
				if (itm.uuid == this.echarts_staff_uuid) {
					this.echarts_title = itm.name
				}
			})
			this.$nextTick(() => {
				this.getPhymentEcharts()
			})
		},

		drawLine() {
			this.$echarts.init(document.getElementById('analysisEcharts')).dispose()
			var x_data = [],
				barData = [],
				lineObject = {}
			for (var i in this.echartsData.length && this.echartsData[0].norm_data[0].data) {
				x_data.push(i)
			}
			this.echartsData.forEach(item => {
				// for (var i in item.norm_data[0].data) {
				// 	x_data.push(i)
				// }

				if (item.cate_type == 7) {
					barData = item.norm_data
				} else {
					lineObject[item.name] = item.norm_data
				}
			})

			// console.log(lineObject, barData, x_data)
			// 基于准备好的dom，初始化echarts实例   图表一
			let myChartOne = this.$echarts.init(document.getElementById('analysisEcharts'))
			var options = {
				title: {
					text: this.echarts_title + '（运动员）分析',
					x: 'center',
					textStyle: {
						//标题内容的样式
						color: '#282828', //京东红
						fontWeight: 'normal', //可选normal(正常)，bold(加粗)，bolder(加粗)，lighter(变细)，100|200|300|400|500...
						fontFamily: 'MicrosoftYaHei', //主题文字字体，默认微软雅黑
						fontSize: 16, //主题文字字体大小，默认为18px
					},
				},
				tooltip: {
					//trigger触发类型 axis 坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用。
					trigger: 'axis',
					//坐标轴指示器配置项。 类型是 十字 指示器颜色是#999
					axisPointer: {
						type: 'cross',
						crossStyle: {
							color: '#999',
						},
					},
				},
				dataZoom: [
					{
						type: 'inside',
						maxValueSpan: 15,
						zoomLock: true,
					},
					{ type: 'slider', show: false },
				],
				legend: {
					type: 'scroll',
					width: '40%',
					// data: ['就诊人数', '就诊人数1', '占比', '占比2'],
					left: '30%',
					top: '10%',
					itemWidth: 20, //
					itemHeight: 3, // 设置高度
				},
				xAxis: [
					{
						type: 'category',
						data: x_data,
						//鼠标移入 显示阴影效果
						axisPointer: {
							type: 'shadow',
						},
						axisTick: {
							//X轴刻度线
							show: false,
						},
						axisLabel: {
							//x轴文字的配置
							show: true,
							textStyle: {
								color: '#333333',
							},
						},

						axisLine: {
							show: true,
							lineStyle: {
								color: '#ccc',
							},
						},
					},
				],
				yAxis: [
					{
						type: 'value',
						name: '',
						min: 0,
						splitLine: { show: false }, //去除网格线
						axisTick: {
							//y轴刻度线
							show: false,
						},
						nameTextStyle: {
							padding: [0, 0, 15, -10],
						},
						axisLine: {
							//y轴线
							show: false,
						},
						splitLine: {
							//网格线
							lineStyle: {
								type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
							},
							show: false, //隐藏或显示
						},
					},
					{
						type: 'value',
						name: '',
						min: 0,
						axisTick: {
							//y轴刻度线
							show: false,
						},
						nameTextStyle: {
							padding: [0, 0, 15, 40],
						},
						axisLine: {
							//y轴线
							show: false,
						},
						axisLabel: {
							show: false,
						},
						splitLine: {
							//网格线
							lineStyle: {
								type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
							},
							show: false, //隐藏或显示
						},
					},
				],
				grid: {
					top: '22%',
					bottom: '13%',
				},
				series: [],
			}

			var obj = []
			// 折线
			var tooltip_name = []
			var index = 0
			for (var i in lineObject) {
				// console.log(lineObject[i].data)
				// for (var j in lineObject[i].data) {
				// 	console.log(lineObject[i].data[j])
				// 	c_data.push(lineObject[i].data[j])
				// }
				lineObject[i].forEach(im => {
					var c_data = []
					tooltip_name.push(im.name)

					for (var j in im.data) {
						c_data.push(im.data[j])
					}
					obj.push({
						name: im.name,
						type: 'line',
						yAxisIndex: 1,
						data: c_data,
						connectNulls: true,
						color: im.color,
					})
				})

				++index
			}

			// 柱状
			var bar = []
			barData.forEach((itm, idx) => {
				var a_data = []
				for (var i in itm.data) {
					a_data.push(itm.data[i])
				}
				tooltip_name.push(itm.name)
				bar.push({
					name: itm.name,
					type: 'bar',
					stack: 'total',
					data: a_data,
					color: itm.color,
					barWidth: 22, //柱图宽度
				})
			})

			options.series = [...obj, ...bar]
			options.legend.data = [...tooltip_name]
			// 绘制图表
			myChartOne.setOption(options)
			this.$echartsResize(myChartOne)
		},
	},
}
</script>

<style lang="scss" scoped>
.body-function {
	padding-top: 10px;
}
.child-top {
	border-radius: 5px;
	.input-from {
		// float: left;
		// flex: 1;
		width: 100%;
		// min-width: 1100px;
		margin-bottom: 0;

		.picker_date {
			float: left;
			margin-top: 20px;
		}

		& > .el-select {
			width: 150px;
			float: left;
			margin-top: 20px;
		}

		.el-select,
		.el-input {
			margin-top: 20px;
		}

		.el-input.el-input--suffix.left-child-input {
			float: left;
			width: 200px;
		}

		.el-cascader {
			margin-top: 18px;
			min-width: 200px;
		}

		.searchBtn {
			margin-top: 20px;
		}
	}

	.operation-button {
		// float: left;
		margin-bottom: 10px;
		// .el-button {
		// 	width: auto;
		// }
		.print_btn {
			background: rgb(53, 200, 203);
		}
	}
}

.analysis {
	padding-top: 20px;
	.echarts_content {
		width: 100%;
		position: relative;

		.echarts_content_fifter {
			position: absolute;
			top: 5%;
			right: 1%;
			z-index: 9;
		}
		.analysis_echarts {
			width: 95%;
			height: 500px;
			margin-top: 30px;
			margin-left: 2.5%;
			position: relative;
		}
		.echarts_tips {
			position: absolute;
			left: 7%;
			top: 10%;
			color: #666;
			font-size: 16px;
			z-index: 999;
		}
	}
}

.el-table {
	tbody {
		/deep/ .el-table__cell {
			padding: 0;
			.cell {
				padding: 0;
			}
			.analysis_table_content1 {
				color: #fff;
				min-height: 45px;
				padding: 10px 3px;
				background: #cac2e4;
			}
			.analysis_table_content2 {
				color: #fff;
				min-height: 45px;
				padding: 10px 3px;
				background: #eb989b;
			}
			.analysis_table_content3 {
				color: #fff;
				min-height: 45px;
				padding: 10px 3px;
				background: #ffcc80;
			}
			.analysis_table_content4 {
				color: #fff;
				min-height: 45px;
				padding: 10px 3px;
				background: #f5b7cc;
			}
		}
	}

	/deep/ .el-table__fixed {
		height: 100% !important;
	}
}

.no_data_view {
	width: 400px;
	height: auto;
	margin: 100px auto;
	img {
		width: 100%;
		height: 100%;
	}
}
</style>
